<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                  Support Ticket <span class="badge badge-square badge-primary -mt-5">{{ no_of_unread_ticket }}</span>
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Support Ticket
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" sm="6" md="2">
                  <v-text-field
                      label="Name"
                      v-model="search.name"
                      outlined
                      dense
                      @input="search.name = $event !== null ? $event : ''"
                      clearable
                      v-on:keyup.enter="searchSupportTicket()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-select
                      label="Type"
                      v-model="search.type"
                      :items="types"
                      item-text="name"
                      item-value="value"
                      outlined
                      dense
                      @input="search.type = $event !== null ? $event : ''"
                      v-on:keyup.enter="searchSupportTicket()"
                      clearable
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="6" md="2" v-if="currentUser.access_type == 'federal'">
                  <v-select
                      label="SCORE"
                      v-model="search.score_id"
                      :items="scores"
                      item-text="name"
                      item-value="id"
                      outlined
                      dense
                      @input="search.score_id = $event !== null ? $event : ''"
                      v-on:keyup.enter="searchSupportTicket()"
                      clearable
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-select
                      label="Ticket Read"
                      v-model="search.is_read"
                      :items="read_types"
                      item-text="name"
                      item-value="value"
                      outlined
                      dense
                      @input="search.is_read = $event !== null ? $event : ''"
                      v-on:keyup.enter="searchSupportTicket()"
                      clearable
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-text-field
                      label="Assigned to"
                      v-model="search.assignedTo"
                      outlined
                      dense
                      @input="search.assignedTo = $event !== null ? $event : ''"
                      clearable
                      v-on:keyup.enter="searchSupportTicket()"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-text-field
                      label="Assigned By"
                      v-model="search.assignedBy"
                      outlined
                      dense
                      @input="search.assignedBy = $event !== null ? $event : ''"
                      clearable
                      v-on:keyup.enter="searchSupportTicket()"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-select
                      v-model="search.assigned_type"
                      :items="assignedTypes"
                      label="Assigned Type"
                      item-text="name"
                      item-value="value"
                      outlined
                      clearable
                      v-on:keyup.enter="searchSupportTicket()"
                      dense
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="search.assigned_date"
                          label="Assigned date"
                          readonly
                          outlined
                          dense
                          clearable
                          v-bind="attrs"
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="search.assigned_date"
                        outlined
                        dense
                        no-title
                        @input="menu = false"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" md="2">
                  <v-select
                      :items="status"
                      v-model="search.is_active"
                      label="Status"
                      item-text="name"
                      item-value="value"
                      outlined
                      clearable
                      v-on:keyup.enter="searchSupportTicket()"
                      dense
                  ></v-select>
                </v-col>

                <v-col cols="12" md="2">
                  <v-btn
                    @click="searchSupportTicket()"
                    class="mt-1 btn btn-block btn-primary"
                    style="color: #fff"
                    :loading="loading"
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>

            </div>

            <div class="table-responsive">

              <v-skeleton-loader
                type="table-thead"
                v-if="loading"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                v-if="loading"
                type="table-row-divider@25"
              >
              </v-skeleton-loader>

              <table class="table">
                <thead>
                <tr class="text-left">
                  <th class="px-3"></th>
                  <th class="px-3">Name</th>
                  <th class="px-3">Category</th>
                  <th class="px-3">Score</th>
                  <th class="px-3">Assigned to</th>
                  <th class="px-3">Assigned by</th>
                  <th class="px-3">Assigned type</th>
                  <th class="px-3">Added date</th>
                  <th class="px-3">Published date</th>
                  <th class="px-3">Status</th>
                </tr>
                </thead>
                <tbody class="mt-5">
                <template>
                  <tr v-bind:class="{ 'bg-gray-200': !item.is_read_admin}" v-for="(item, index) in support_tickets" :key="index">
                    <td class="ps-9">
                      <div class="d-flex align-items-center">
                          <v-checkbox @change="getMark($event, item)" v-model="checkBox[index]" :ripple="false"></v-checkbox>
                      </div>
                    </td>
                    <td class="px-2">
                      <a @click="viewSupportThread(item)" class="d-flex align-items-center">
                        <div class="symbol symbol-35px me-3">
                          <div class="symbol-label bg-light-danger">
                            <span class="text-danger">{{ item.symbol_label }}</span>
                          </div>
                        </div>
                        <span class="text-primary font-weight-bolder text-hover-primary mb-1">{{ item.name }}</span>
                      </a>
                    </td>
                    <td class="px-3">
                      <span class="text-dark-75 mb-1 mt-2 d-flex align-items-center">
                        {{item.category_name }}
                      </span>
                    </td>
                    <td class="px-3">
                      <span class="text-dark-75 mb-1 mt-2 d-flex align-items-center">
                        {{item.score }}
                      </span>
                    </td>
                    <td class="px-3">
                      <span class="text-dark-75 mb-1 mt-2 d-flex align-items-center">
                        {{item.assignedTo ? item.assignedTo : 'NA' }}
                      </span>
                    </td>
                    <td class="px-3">
                      <span class="text-dark-75 mb-1 mt-2 d-flex align-items-center">
                        {{item.assignedBy ? item.assignedBy : 'NA' }}
                      </span>
                    </td>
                    <td class="px-3">
                      <span class="text-dark-75 mb-1 mt-2 d-flex align-items-center">
                        {{item.formatted_assigned_date ? item.formatted_assigned_date : 'NA' }}
                      </span>
                    </td>
                    <td class="px-3">
                      <a  class="text-dark-75 mb-1 mt-2 d-flex align-items-center">
                        {{ item.formatted_created_at}}
                      </a>
                    </td>
                    <td class="px-3">
                      <span class="fw-bold">{{item.readable_created_at}}</span>
                    </td>
                    <td class="px-3">
                     <span class="badge"
                           v-bind:class="{ 'badge-success': item.ticket_status == 'In Progress', 'badge-danger': item.ticket_status != 'In Progress'}"
                     >{{ item.ticket_status }}</span>
                    </td>
                  </tr>
                  <tr v-if="support_tickets.length == 0">
                    <td colspan="10" class="text-center">
                      <strong>No Items added</strong>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination
                  v-if="support_tickets.length > 0"
                  class="pull-right mt-7"
                  @input="getAllSupportTicket"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
                  :disabled="loading"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import SupportTicketService from "@/services/support/support-ticket/SupportTicketService";
import ScoreService from "@/services/score/score/ScoreService";

const supportTicket=new SupportTicketService();
const score = new ScoreService();
export default {
  data() {
    return {
      support_tickets:[],
      candidateId: null,
      total: null,
      perPage: null,
      page: null,
      loading: false,
      menu: false,
      isLoading: false,
      checkBox: [false],
      scores: [],
      currentUser: {},
      search:{
        name: '',
        type: '',
        is_read: '',
        score_id: '',
        assignedTo: '',
        assignedBy: '',
        assigned_date: '',
        assigned_type: '',
        is_active: '',
      },
      assignedTypes: [
        {name: 'Federal', value: 'federal'},
        {name: 'Score', value: 'score'}
      ],
      status: [
        {name: 'Active', value: 1},
        {name: 'Inactive', value: 0},
      ],
      read_types: [
        {name: 'Read Ticket', value: '1'},
        {name: 'UnRead Ticket', value: '0'}
      ],
      types: [
        {name: 'General', value: 'general'},
        {name: 'Extension', value: 'extension'},
        {name: 'Refund', value: 'refund'},
        {name: 'Payment', value: 'payment'},
        {name: 'Appeal', value: 'appeal'},
        {name: 'Feedback', value: 'feedback'},
        {name: 'Shipment', value: 'shipment'},
        {name: 'Other', value: 'other'},
      ],
    }
  },
  mounted() {
    this.candidateId = this.$route.params.candidateId;
    this.getAllSupportTicket();
    this.getAllScore();
    this.getCurrentUser();
  },
  methods:{

    getAllSupportTicket(){
      this.loading =  true;
      supportTicket
          .getAllCandidateTicket(this.candidateId, this.search,this.page)
          .then(response => {
            this.support_tickets=response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.loading =  false;
            this.getUnReadTicket();
          })
          .catch((err) => {});
    },
    searchSupportTicket(){
      this.getAllSupportTicket();
    },
    viewSupportThread(ticket){
      if(!ticket.is_read_admin) {
        ticket.is_read_admin = true;
        supportTicket.update(ticket.id, ticket).then(response =>{
          this.getAllSupportTicket();
        }).catch((err) => {
          this.$snotify.error("Oops something went wrong");
        });
      }
      this.$router.push({
        name:'support-ticket-view',
        params:{supportTicketId: ticket.id}
      });
    },

    getAllScore() {
        score.all().then(response => {
          this.scores = response.data.scores;
        }).catch((err) => {
          this.$snotify.error("Oops something went wrong");
        });
    },

    getCurrentUser() {
      this.currentUser = this.$store.getters.currentUser;
    },
  }
}
</script>